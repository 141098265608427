.download-file i {
  font-size: 23px;
}
.download-file span {
  font-size: 16px;
}
.download-file:hover {
  cursor: pointer;
}
.download-only {
  color: #01602a;
  cursor: pointer;
}
.download-only i {
  margin-right: 8px;
}
