.off {
  width: 8px;
  height: 8px;
  padding: 3px;
  margin: 2px;
  border: none;
  border-radius: 100px;
  background: #acb1c0;
}

.on {
  width: 14px;
  height: 8px;
  padding: 3px;
  margin: 2px;
  border: none;
  border-radius: 100px;
  background: #01602a;
}
