/** menu **/
.menu {
  @media (max-height: 775px) {
    overflow: auto;
  }
}
.menu::-webkit-scrollbar {
  width: 8px;
}
.menu::-webkit-scrollbar-track {
  background: #01602a;
}
.menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
}
.menu {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: auto;
  background: #01602a;
  padding: 0 16px;
  float: left;
}
.menu .top-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu .top-menu .wrapper-logo img {
  max-width: 150px;
  width: 120px;
  height: auto;
  padding: 20px 0;
}
.menu .top-menu .wrapper-icon-menu-mobile .icon {
  color: #d5db26;
  font-size: 20px;
}
.menu .wrapper-usuario {
  display: none;
}
.wrapper-usuario .usuario {
  margin-top: 20px;
}
.menu .wrapper-links-menu {
  display: none;
}

/** links menu **/
.wrapper-links-menu .links {
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}
.wrapper-links-menu .links .link {
  text-decoration: none;
  color: #ffffff;
}
.wrapper-links-menu .links li a {
  cursor: pointer;
}
.wrapper-links-menu .links hr {
  border: 0.5px solid #063649;
  margin: 12px;
}
.wrapper-links-menu .links .link .wrapper {
  display: flex;
  align-items: center;
  padding: 0px 0px 18px 12px;
  color: #ffffff;
}
.wrapper-links-menu .links .link .wrapper .icon {
  font-size: 24px;
  opacity: 0.4;
  margin-right: 16px;
}
.wrapper-links-menu .links .link.active .icon {
  color: #DEE9D3;
  opacity: 1;
}
.wrapper-links-menu .links .link .wrapper .text {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  line-height: 17px;
}

/** Canais de Atendimento **/
.wrapper-links-menu .wrapper .iconContato {
  font-size: 18px;
  opacity: 0.4;
  color: #FFFFFF;
  padding-top: 4px;
  position: absolute;
}
.wrapper-links-menu .wrapper .textTitle {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  line-height: 17px;
  color: #ffffff;
}
.wrapper-links-menu .wrapper .textInfo {
  font-size: 12px;
  font-family: "Inter";
  line-height: 12px;
  color: #FFFFFF;
  padding-left: 24px;
}
.wrapper-links-menu .wrapper .tel {
  font-size: 12px;
  font-family: "Inter";
  line-height: 12px;
  color: #ffffff;
}
.wrapper-links-menu .wrapper .subtitle {
  font-size: 10px;
}
.wrapper-links-menu .wrapper a {
  text-decoration: none;
}

/** Canais de Atendimento Mobile **/
.menu-mobile-links .wrapper .iconContato {
  font-size: 18px;
  opacity: 0.4;
  color: #7b808c;
  padding-top: 4px;
  padding-left: 14px;
  position: absolute;
}
.menu-mobile-links .wrapper .textTitle {
  font-size: 14px;
  font-family: "Metropolis-semiBold";
  color: #7b808c;
  padding: 24px 16px;
}
.menu-mobile-links .wrapper .textInfo {
  font-size: 12px;
  opacity: 0.7;
  font-family: "Metropolis";
  color: #7b808c;
  padding-left: 38px;
}
.menu-mobile-links .wrapper .subtitle {
  font-size: 10px;
}
.menu-mobile-links .wrapper .tel {
  font-size: 12px;
  font-family: "Metropolis-Bold";
  color: #7b808c;
}

/** menu mobile **/
.menu-mobile {
  position: fixed;
  background: #ffffff;
  width: 80%;
  right: 0;
  z-index: 10;
  transform: translateX(100%);
  bottom: 0;
  top: 0;
  overflow: auto;
}
.menu-mobile.open {
  transform: translateX(0);
  transition: 0.5s;
}
.menu-mobile.close {
  transition: 0.5s;
}
.menu-mobile .menu-mobile-header {
  background: #01602A;
  padding: 0px 0px 0px 0px;
  height: 400px;
}
.menu-mobile .wrapper-links-menu .links .link .wrapper {
  color: #7b808c;
}
.menu-mobile .wrapper-links-menu .links .link .wrapper .text {
  font-size: 12px;
}
.menu-mobile .wrapper-links-menu .links hr {
  border: 0.5px solid #7b808c;
  margin: 16px;
  opacity: 0.4;
}
.menu-mobile .wrapper-links-menu .links li:last-child {
  bottom: 0;
}

/* posicao da action sair no safari mobile */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .menu-mobile .wrapper-links-menu .links li:last-child {
      position: relative;
      margin-top: 30px;
    }
  }
}
/** usuário **/
.wrapper-usuario .usuario {
  color: #ffffff;
}
.wrapper-usuario .nome {
  font-family: "Metropolis-bold";
  font-size: 20px;
}
.wrapper-usuario.usuario .empresa {
  text-transform: uppercase;
}
