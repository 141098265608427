
/** form  **/
.wrapper-form-agendamento .item-flex.item-50 div {flex: 1; max-height: 48px}
/** serach **/
.wrapper-sevicos > h2, .wrapper-fornecedores h2  {font-size:16px;font-family:'Metropolis-semiBold';color:#3A3D42;margin:24px 0}
.wrapper-fornecedores h2{margin:32px 0}
.sem-fornecedores {text-align: center;}
.fake-search{font-size:14px;color:#7B808C;background:#F0F1F5;padding:16px;border-radius:3px;margin: 16px 0; cursor:pointer}
.fake-search .icon{float:right;font-size:20px;color:#404040}

/* modal serviços */
.modal-header--desktop{display:none}
.modal-header .icon:last-child{display:none}

.modal-pesquisar-servicos .modal-body{height:calc(100vh - 50px); overflow: auto}
.modal.modal-pesquisar-servicos .modal-header{background:#fff;box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.05), 0 2px 38px 0 rgba(0, 0, 0, 0.07);display:flex;font-size: 12px}
.modal.modal-pesquisar-servicos .modal-header .icon{color:#404040;margin-right: 16px}
.modal.modal-pesquisar-servicos .modal-header .search{width:100%;outline-color:#fff0;border:none}
.modal-pesquisar-servicos .modal-body .wrapper-chips{padding:0 8px 16px;min-width:100%}
.modal-pesquisar-servicos .modal-body .wrapper-chips hr{margin-top: 16px;border: 1px solid #E1E1E1}
.modal-pesquisar-servicos .wrapper-button-agendamento{position:fixed;width:100%;bottom:0;left:0;padding:0 15px;background:#fff;z-index:2}
.result{padding:0 4px; overflow: auto; max-height: 70vh;margin-bottom:20vh}
.result .item-result{padding:16px 0}
.mensagem-modal-aviso-cancelamento{margin-top:2em;margin-bottom: 2em}

/** fornecedores **/
.lista-fornecedore .item-lista-fornecedores{display:flex;align-items:center;justify-content:space-between;flex-wrap:wrap}
.lista-fornecedore .item-lista-fornecedores .endereco{font-size:10px;color:#7B808C;margin-left:38px;border-bottom:1px solid #E1E1E1;padding-bottom:16px; width:100%}
.item-lista-fornecedores{margin:16px 0}

/* data e hora agendamento */
.wrapperfornecedores-data-local{margin-top:60px}
.wrapperfornecedores-data-local h2{font-size:16px;font-family:'Metropolis-semiBold';color:#3A3D42}
.wrapperfornecedores-data-local .subtitle{font-size:12px;color:#7B808C;font-family:'Metropolis';font-weight:400}
.wrapperfornecedores-data-local .container-form-date-time{width:100%;margin-right:0;display:flex;float:left}
.wrapperfornecedores-data-local .container-form-date-time .wrapper-input:first-child{margin-right:8px}
.wrapperfornecedores-data-local .container-form-date-time .erro {margin-right:8px; border: red;}
.wrapperfornecedores-data-local .container-form-date-time ~ span{color:#7B808C;line-height:1.5;margin-top:19px;display:none}
.wrapper-form-agendamento .wrapper-button-agendamento{display:block;width:100%}
.wrapper-form-agendamento .wrapper-button-agendamento .button{float:right}
/* button icon */
.wrapper-form-agendamento .wrapper-button-agendamento .button-icon{width:auto}
.wrapper-form-agendamento .wrapper-button-agendamento .button-icon i{margin-left:8px}
.button.button-icon i {margin-left: 8px;}
  /* alinhar dois itens de formulario */
  .two-item-form{display:flex;flex-wrap: wrap}
  .two-item-form .item-flex{width: 100%}



  /** tela agendamento **/
  @media (min-width: 768px) {

    /** form **/
    .wrapper-form-agendamento .item-flex{width:calc(50% - 8px)}
    .wrapper-form-agendamento{display:flex;flex-wrap:wrap;justify-content:space-around}
    .wrapper-form-agendamento .item-flex:first-child{display:flex}
    .wrapper-form-agendamento .item-flex:first-child .info-read{margin-right:15px}
    .wrapper-form-agendamento .item-flex.item-50:first-child .wrapper-input{margin-left: 15px}
    .wrapper-form-agendamento .item-flex .info-read .title{white-space:nowrap}
    .wrapper-form-agendamento .wrapper-button-agendamento .button{width:auto}

      /* alinhar dois itens de formulario */
      .two-item-form .item-flex{flex: 1 1;width: auto}
      .two-item-form .item-flex:first-child{margin-right:10px}

    /* faker search - para abrir a modal de search */
    .fake-search .icon{display:inline}

    /* modal serviços */
    .modal-header--desktop{display:block}
    .modal-header .icon:last-child{display:block}
    .modal-header--desktop{padding:16px}
    .modal-header--desktop .icon{font-size:24px;color:#3A3D42; cursor: pointer}
    .modal.modal-pesquisar-servicos .modal-header{box-shadow:none!important;background:#F0F1F5;margin:0 16px}
    .modal.modal-pesquisar-servicos .modal-header .icon:first-child{display:none}
    .modal.modal-pesquisar-servicos .modal-header .search{background:#f0f1f5}

    .result{padding:0 4px; overflow: auto; max-height: 50vh;margin-bottom: 40px}

    /** fornecedores **/
    .lista-fornecedore .item-lista-fornecedores .endereco{border-bottom: 0; padding-bottom:0; width: auto}

      /** data e hora agendamento **/
  .wrapperfornecedores-data-local .container-form-date-time ~ span{display:block}
  .wrapperfornecedores-data-local .container-form-date-time{width:50%;margin-right:16px}
  
  
}
