/** header internal mobile **/
.hearder-internal{position: fixed;width: 100%;background:#01602A;color:#fff;display:flex;justify-content:space-between;align-items:center;padding: 12px 16px;font-family:'Metropolis-semiBold';z-index: 2;}
.hearder-internal span{flex:1}
.hearder-internal .icon{font-size:24px;color:#d5db26}
.hearder-internal .title{text-align:center;flex:2}
.hearder-internal .action{text-align:right}
.hearder-internal .icon{text-decoration:none}
/* esconde o menu */
.hearder-internal ~ .menu{display: none}

/* coloca o padding no main por causa do menu fixo */
.hearder-internal ~ main {padding-top: 48px}


@media (min-width: 1025px) {
    /** content header-internal **/
    .hearder-internal {display: none}
    .hearder-internal ~ main {padding-top: 0}
    .hearder-internal ~ .menu{display: block}
  }
