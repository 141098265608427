/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {

}
  
  /* 
    ##Device = Laptops, Desktops 
    ##Screen = B/w 1025px 
  */
  
  @media (min-width: 1025px) {
    /* geral */
    h1{font-size: 32px}
     /* menu */
     .menu{width:320px;height:100vh; padding: 0px 20px 15px 20px;}

     /* usuário */
     .menu .wrapper-usuario{display: block;}

     /* links menu */
     .menu .wrapper-links-menu{display: block}
     .menu .top-menu .wrapper-icon-menu-mobile{display: none}

     /* content */
     main{ width: calc(100% - 320px);margin-left: 320px;} 
     .content-primary{padding: 60px 60px 16px 60px}
     .content-secondary{padding: 16px 60px}

     /* bottom navigation */
    .wrapper-bottom-navigation{display: none}

    /** content header-internal **/
    .hearder-internal {display: none;}
  }
  
  /* tablet and desktop */
  @media (min-width: 768px) {
    /* logo menu */
    .menu .top-menu .wrapper-logo img {padding-bottom:0}

    /** info read  **/
    .info-read .title{font-size: 1em}

    /** card historico **/
    .card-historic .section-left,.card-historic .section-right{display:flex;align-items:center;flex:1;justify-content:space-around}
    .card-historic .section-left .info-primary,.card-historic .section-left .info-secondary{flex:1}
    .card-historic .section-right{flex-direction:row-reverse}
    .card-historic .section-right .action{text-align:right}
    .card-historic .section-right .icon{font-size:24px}
    .card-historic .section-left .info-primary{line-height: 1.76}
    .card-historic .section-left .info-secondary{text-align:center}

    /** lista decimal **/
    .list-decimal li:before{vertical-align: baseline}
        
    /** modal **/
    .modal{max-width:500px;margin:1.75rem auto;top:50%;transform:translateY(-50%);}
    .modal .modal-header{background:#fff}
    .modal .modal-header .icon{color:#01602A}
    .modal .modal-body{height:auto;min-height:300px;overflow-y:auto;max-height: 70vh;overflow: auto}
    .modal .modal-footer{display:flex;flex-direction:row-reverse}
    .modal .modal-footer .button{width:auto;padding:11px 36px}

    /* modal servicos  */
    .modal-pesquisar-servicos.modal .modal-body{height:auto}

    /** codigo roubo - modal **/
    .codigo-roubo{margin:0}
    .codigo-roubo .codigo{font-size:32px}

    /* modal indicar condutor */
    .modal-indicar-condutor .modal{transform:translateY(-60%)}

    /** Slider **/
    .slider .slider-header .action, .slider .slider-header .title,
    .slider .slider-header .icon.icon-mobile {display: none}  
  }
  
  /* All mobile */
  @media (max-width: 768px) {

    main {padding-bottom: 50px}

    /** menu mobile **/
    .menu-mobile .wrapper-select-placa{display: none;}

    /** card historic **/
    .card-historic{align-items: flex-end}
    .card-historic .section-left .info-primary,.card-historic .section-right .icon{margin-bottom:8px}
    .card-historic .section-left .info-secondary,.card-historic .section-right div{margin-top:8px}
    .card-historic .button {display: none}

    /* Slider */
    .slider{width:100%}
    .slider .slider-header{height:48px}
    .slider .slider-body{padding:16px; padding-bottom: 97px}
    .slider .slider-header .icon.icon-desktop {display: none}
    
    /* Select placa */
    .wrapper-select-placa{margin-top:0}

    /* Modal upload */
    .modal-upload{height: 100vh;background: #fff}
    .modal-upload .modal-body{overflow: auto;max-height: calc(100vh - 140px)}
    .modal-upload .modal{height:100%}
    .modal-upload .modal-footer{position:absolute;bottom:70px;width:100%}

    /* Tela autorizar cookie */
    .autorizar-cookie-wrapper .autorizar-cookie {width: auto}

    /* Layout internal */
    .container-with-bottom-navigation{height:100%;width:100%;padding-bottom:49px;display:table}

    /* Modal indicar condutor */
    .modal-indicar-condutor .modal{width:100%;height:100%;max-width:100%}
    .modal-indicar-condutor .modal .modal-body iframe{height:100%}

    /* Modal interposicao de recursos */
    .wapper-modal-interposicao-recursos .modal{position:absolute;left:0;right:0;margin:0 auto;top:50%;transform:translateY(-50%)}

    .wrapper-iframe{padding:0}
    .iframe-indicar-condutor{height: calc(100vh - 130px);}

  }
    

  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    /* bottom navigation */
      
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* geral */
    h1{font-size: 32px}

     /* usuário */
    .menu .wrapper-usuario{display: block}
    .wrapper-usuario .usuario div{display: inline}
    .wrapper-usuario .usuario .nome{margin-right: 16px}
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    /** menu mobile  **/
    .menu-mobile .wrapper-links-menu{overflow-y:auto;height:calc(100vh - 104px)}
    .menu-mobile .wrapper-links-menu .links li:last-child{position:relative}

    /** lista decimal **/
    .wrapper-list-decimal .list-decimal li:before{vertical-align: baseline}
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
 
    
  }