/** card **/
.wrapper-card a {
  text-decoration: none;
}
.wrapper-card .card {
  position: relative;
  padding: 0 16px;
  color: #7b808c;
  font-size: 0.8333333333333334em;
  margin-bottom: 16px;
  background: #fff;
}
.wrapper-card .card:before {
  content: "";
  height: 100%;
  position: absolute;
  width: 4px;
  top: 0;
  left: 0;
  border-radius: 3px 0 0 3px;
}
.wrapper-card .card .title {
  margin: 12px 0;
}
.wrapper-card .card .title .text {
  font-family: "Inter";
  text-transform: uppercase;
}
.wrapper-card .card .title .icon {
  margin-right: 8px;
  font-size: 1.2em;
}
.wrapper-card .card .title .arrow {
  position: absolute;
  right: 20px;
}
.wrapper-card .card .description,
.wrapper-card .card .bottom-info {
  margin: 16px 0;
}
.wrapper-card .card .description .primary-info {
  font-family: "Inter";
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #3a3d42;
}
.wrapper-card .card .bottom-info .item .value {
  font-family: "Inter";
  font-size: 16px;
  margin-left: 8px;
}
.card .bottom-info .item {
  margin-right: 30px;
  font-family: "Inter";
  font-weight: bold;
}

/** card historico mobile **/
.wrapper-card a {
  text-decoration: none;
}
.wrapper-card .card-historico {
  position: relative;
  padding: 2px 0 10px 16px;
  margin: 10px 0px 20px 0px;
  color: #7b808c;
  font-size: 0.8333333333333334em;
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(28, 76, 97, 0.2);
  border-radius: 16px;
}
.wrapper-card .card-historico .title {
  margin: 12px 0;
}
.wrapper-card .card-historico .title .text {
  font-family: "Inter";
  text-transform: uppercase;
}
.wrapper-card .card-historico .title .icon {
  margin-right: 8px;
  font-size: 1.2em;
}
.wrapper-card .card-historico .title .arrow {
  position: absolute;
  right: 16.5px;
}
.wrapper-card .card-historico .description,
.wrapper-card .card-historico .bottom-info {
  margin: 4px 0;
}
.wrapper-card .card-historico .description .primary-info {
  font-family: "Inter";
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #3a3d42;
}
.wrapper-card .card-historico .bottom-info .item .value {
  font-family: "Inter";
  font-size: 16px;
  margin-left: 8px;
}
.card-historico .bottom-info .item {
  margin-right: 30px;
  font-family: "Inter";
  font-weight: bold;
}
.wrapper-card .card-historico .bottom-info_icon {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-family: "Inter";
  line-height: 12px;
}
.wrapper-card .card .description,
.wrapper-card .card .bottom-info {
  margin: 16px 0;
}
.wrapper-card-historico
  .card-historico
  .bottom-info_icon
  .wrapper-info
  .info-title {
  color: #3a3d42;
  margin: 0 0 0 0;
}
.wrapper-card .card-historico .bottom-info_icon .wrapper-icon .icon {
  font-size: 20px;
  color: #01602A;
}

/* card icon */
.wrapper-card .card .bottom-info_icon {
  margin: 0 0 10px 0;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-family: "Inter";
  line-height: 12px;
}
.wrapper-card .card .bottom-info_icon .wrapper-info {
  max-width: 80%;
}
.wrapper-card .card .bottom-info_icon .wrapper-info .info-title {
  color: #3a3d42;
  margin-bottom: 6px;
}
.wrapper-card .card .bottom-info_icon .wrapper-icon .icon {
  font-size: 20px;
  color: #01602A;
}

/* card historico */
.card.card-historic {
  display: flex;
  justify-content: space-between;
}
.card-historic .action .icon {
  cursor: pointer;
}
.card-historic .section-left .info-primary {
  color: #3a3d42;
  font-family: "Inter";
  font-size: 14px;
  margin: 16px 0;
}
.card-historic .section-left .info-secondary {
  color: #7b808c;
  font-size: 10px;
  margin: 16px 0;
}
.card-historic .section-right .action,
.card-historic .section-right div {
  flex: 1;
  text-align: center;
}
.card-historic .section-right .icon {
  color: #ff0000bf;
  font-size: 16px;
  display: block;
  margin: 16px 0;
  text-align: right;
}
.card-historic .section-right {
  text-align: right;
}
.card-historic .section-right div {
  margin: 16px 0;
}
.card-historic .button {
  width: auto;
  padding: 9px 16px;
  margin: 12px 0px;
}

/* cores do card 
.wrapper-card .card.amarelo:before{background: #FDC100}
.wrapper-card .card.amarelo-claro:before{background: #DEE9D3}
.wrapper-card .card.verde:before{background: #00984a}
.wrapper-card .card.vermelho:before{background: #f85843}
.wrapper-card .card.azul:before{background:#01602A}
 cor card disabilitado 
.card.disabled div, .card.disabled span,.card.disabled .agendamento-fornecedor-endereco  .fornecedor {color: #BEBEBE}
.card.disabled:before {background: #bebeb3}*/

.card.disabled div,
.card.disabled span,
.card.disabled .agendamento-fornecedor-endereco .fornecedor {
  color: #979797;
}
