.initial {
    font-family: Inter;
    font-size: 10px;
    letter-spacing: 0.15px;
    color: #3a3d42;
}

.greenOn{
    font-family: Inter;
    font-size: 10px;
    letter-spacing: 0.15px;
    color:  #00A651;
}

.redOn{
    font-family: Inter;
    font-size: 10px;
    letter-spacing: 0.15px;
    color: #FF0000;
}

