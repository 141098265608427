/** bottom navigation **/
.wrapper-bottom-navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: #fff;
  padding: 5px 16px;
  box-shadow: 0px 2px 38px rgba(28, 76, 97, 0.2),
    0px 9px 15px rgba(0, 0, 0, 0.05);
  border: solid 1px #eee;
  border-radius: 16px 16px 0 0;
}
.wrapper-bottom-navigation .bottom-navigation {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  font-size: 10px;
  color: #01602a;
}
.wrapper-bottom-navigation .bottom-navigation .item-navigation {
  text-align: center;
  flex: 1;
}
.wrapper-bottom-navigation .bottom-navigation .item-navigation a span {
  opacity: 0.5;
}
.wrapper-bottom-navigation .bottom-navigation .item-navigation a {
  text-decoration: none;
  color: #01602a;
}
.wrapper-bottom-navigation .bottom-navigation .item-navigation a.active span {
  font-family: Inter;
  opacity: 1;
}
.wrapper-bottom-navigation .bottom-navigation .item-navigation span {
  display: block;
  width: 100%;
}
.wrapper-bottom-navigation .bottom-navigation .item-navigation .icon {
  font-size: 20px;
  margin-bottom: 5px;
}
