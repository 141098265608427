@media (min-width: 768px) {
  /*** tela editar km * */
  section.edit-km h1 {margin-bottom: 32px}
  .content-form-edit-km{width:100%;display:flex;justify-content:space-between;flex-wrap:wrap}
  .content-form-edit-km > div,.content-form-edit-km .form{flex-grow:0;flex-shrink:0;flex-basis:45%}
  .content-form-edit-km .button {max-width: 270px;float: right}
  .content-form-edit-km .form .button{max-width:288px;float:right}
  .content-form-edit-km .info-read,.content-form-edit-km .wrapper-input{margin-bottom:24px}
  .content-form-edit-km .input-km-date{display:flex}
  .content-form-edit-km .input-km-date .wrapper-input:first-child{margin-right:8px}

}