.turnOn {
  background-color: #01602a;
  color: #ffffff;
}

.turnOff {
  background-color: #ffffff;
  color: #01602a;
}

.turnOnMobile {
  background-color: #01602a;
  color: #ffffff;
}

.turnOffMobile {
  background-color: #ffffff;
  color: #01602a;
}
