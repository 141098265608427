/** reset css **/
* {
  box-sizing: border-box;
}

ul {
  list-style: none;
}

/** fonts **/
@font-face {
  font-family: "Metropolis";
  src: url("../fonts/woff2/Metropolis-Regular.woff2") format("woff2"),
    url("../fonts/woff/Metropolis-Regular.woff") format("woff");
}

@font-face {
  font-family: "Metropolis-bold";
  src: url("../fonts/woff2/Metropolis-Bold.woff2") format("woff2"),
    url("../fonts/woff/Metropolis-Bold.woff") format("woff");
}

@font-face {
  font-family: "Metropolis-semiBold";
  src: url("../fonts/woff2/Metropolis-SemiBold.woff2") format("woff2"),
    url("../fonts/woff/Metropolis-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Metropolis-ExtraBold";
  src: url("../fonts/woff2/Metropolis-ExtraBold.woff2") format("woff2"),
    url("../fonts/woff/Metropolis-ExtraBold.woff") format("woff");
}

/** configuração padrão de font **/
body {
  font-family: "Inter";
  font-size: 12px;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  line-height: 1.2;
}

h1 {
  font-family: "Inter";
  font-weight: bold;
  font-size: 24px;
}

h2.subtitle {
  font-size: 14px;
  color: #7b808c;
  margin-bottom: 40px;
  font-weight: normal;
}

/** utils **/
.background-gray {
  background: #eeeeee;
}

.padding-default {
  padding: 16px;
}

.margin-default {
  margin: 16px;
}
/* overlay */
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 3;
}

.overlay-animate-opacity {
  animation: opac 1s;
}

@keyframes opac {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/** content **/
html,
body,
#root,
.App,
main,
#root > span > div {
  height: 100%;
  width: 100%;
}

main {
  width: 100%;
  float: left;
  display: table;
}

.content-primary,
.content-secondary {
  padding: 16px;
  width: 100%;
  float: left;
}

.content-primary {
  background: #fff;
}

.content-secondary {
  background: #eeeeee;
}

/** subheader **/
.subheader {
  padding: 16px 0;
  font-size: 10px;
  color: #7b808c;
  text-transform: uppercase;
}

/** header pages bottom navigation **/
.header-bottom-navigation .title-page {
  font-size: 24px;
  color: #fff;
  font-family: "Metropolis-ExtraBold";
  margin: 6px 0 24px;
}

.menu.header-bottom-navigation ~ main {
  padding-top: 126px;
  display: table;
}

/** card lista de multas **/
.card.card-list-multas {
  padding-top: 12px;
  padding-bottom: 12px;
  background: #ffffff;
  box-shadow: 0px 11px 30px rgba(28, 76, 97, 0.15);
  border-radius: 16px;
}

.card-list-multas .multa-info {
  margin-bottom: 8px;
  font-family: "Inter";
  text-transform: uppercase;
}

.card-list-multas .multa-descricao {
  color: #3a3d42;
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
  margin: 3px 0 24px;
  text-transform: lowercase;
}

.card-list-multas .multa-descricao:first-letter {
  text-transform: uppercase;
}

.card-list-multas .multa-situacao {
  color: #3a3d42;
  font-size: 12px;
  font-family: Inter;
  line-height: 20px;
  width: 50%;
  display: inline-block;
  text-align: right;
}

.card-list-multas .multa-situacao-vermelho {
  color: #ff0000;
  font-size: 12px;
  font-family: Inter;
  line-height: 20px;
  width: 50%;
  display: inline-block;
  text-align: right;
}

.card-list-multas .multa-situacao-amarelo {
  color: #d6a300;
  font-size: 12px;
  font-family: Inter;
  line-height: 20px;
  width: 50%;
  display: inline-block;
  text-align: right;
}

.card-list-multas .multa-valor {
  color: #3a3d42;
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
  width: 50%;
  display: inline-block;
}

.card-list-multas .multa-valor-vermelho {
  color: #ff0000;
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
  width: 50%;
  display: inline-block;
}

.card-list-multas .multa-valor-amarelo {
  color: #d6a300;
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
  width: 50%;
  display: inline-block;
}

.card-list-multas .multa-vencimento {
  width: 50%;
  display: inline-block;
  text-align: right;
}

.card-list-multas.vermelho .multa-valor {
  color: #f85843;
}

/** card lista agendamentos **/
.card.card-list-agendamentos {
  display: flex;
  padding: 16px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 11px 30px rgba(28, 76, 97, 0.15);
  border-radius: 16px;
}

.card-list-agendamentos .agendamento-data {
  width: 32px;
  margin-right: 24px;
  color: #01602a;
}

.card-list-agendamentos .agendamento-data .data {
  font-family: "Inter";
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 3px;
}

.card-list-agendamentos .agendamento-fornecedor-endereco .fornecedor {
  font-family: "Inter";
  color: #3a3d42;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
}

.card-list-agendamentos .fornecedor,
.card-list-agendamentos .endereco {
  font-family: "Inter";
  text-transform: lowercase;
}

.card-list-agendamentos .fornecedor:first-letter,
.card-list-agendamentos .endereco:first-letter {
  text-transform: uppercase;
}

/** card roubo home **/
.card-roubo-home {
  margin-right: 35px;
}

.card-roubo-home .left .icon {
  margin-right: 8px;
  font-size: 1.2em;
}

.card-roubo-home .left .text {
  font-family: "Metropolis-semiBold";
  text-transform: uppercase;
}

/** input  **/
.wrapper-input {
  background: #fafafa;
  display: block;
  width: 100%;
  padding: 15px 8px;
  margin-bottom: 16px;
  position: relative;
  border: 1px solid #3a3d42;
  box-sizing: border-box;
  border-radius: 8px;
}

.wrapper-input .icon {
  position: absolute;
  right: 8px;
  bottom: 8px;
  font-size: 14px;
}

.wrapper-input.readonly {
  background: #fff;
}

.wrapper-input .group-input {
  position: relative;
}

.wrapper-input .group-input .input {
  font-size: 14px;
  display: block;
  width: 90%;
  border: none;
  background: transparent;
  color: #3a3d42;
  font-family: "Metropolis-semiBold";
  top: 8px;
  position: relative;
}

.wrapper-input .group-input .input:focus {
  outline: none;
}

.wrapper-input .group-input .label {
  color: #3a3d42;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  top: 8px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.wrapper-input .group-input .input:focus ~ .label,
.wrapper-input .group-input .input:valid ~ .label,
.wrapper-input .group-input .input[readonly] ~ .label {
  top: -8px;
  font-size: 10px;
  color: #3a3d42;
}

.wrapper-input .group-input .input[readonly] {
  font-family: "Inter";
}

.wrapper-input.has-error {
  border: 1px solid #f13b3b;
}

.wrapper-input.has-error .group-input input,
.wrapper-input.has-error .group-input .label,
.wrapper-input.has-error .group-input textarea,
.wrapper-input.has-error .group-input select {
  color: #f13b3b !important;
}

/* input suggest */
.wrapper-input .group-input .react-autosuggest__input {
  font-size: 14px;
  display: block;
  width: 90%;
  border: none;
  background: transparent;
  color: #3a3d42;
  font-family: "Metropolis-semiBold";
  top: 8px;
  position: relative;
}

.wrapper-input .group-input .react-autosuggest__input:focus ~ .label,
.wrapper-input .group-input .react-autosuggest__input:valid ~ .label,
.wrapper-input .group-input .react-autosuggest__input[readonly] ~ .label {
  top: -8px;
  font-size: 10px;
  color: #7b808c;
}

.wrapper-input .group-input .react-autosuggest__input:focus {
  outline: none;
}

/** custom select */
.select {
  position: relative;
  line-height: 2;
}

select {
  padding-right: 28px;
  cursor: pointer;
  border: none;
  background: #eee;
  color: #01602a;
  outline: transparent;
  font-family: "Metropolis-semiBold";
  font-size: 10px;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.select::after {
  content: "\e942";
  position: absolute;
  top: 5px;
  right: 0;
  bottom: 0;
  background: #eee;
  pointer-events: none;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

/** button **/
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #01602a;
  border-radius: 16px;
  border: transparent;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  font-family: "Inter", sans-serif;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 16px;
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
}
/** float button **/
.button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.home .button.float-button {
  display: none;
}

.button.float-button {
  background: #DEE9D3;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.agendamentos .float-button i {
  line-height: 0;
}
/** button-ligth-icon **/
.button-light-icon {
  color: #01602a;
  font-family: "Inter";
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
}

@media (max-width: 1025px) {
  .button-light-icon {
    margin: 0 0 20px 0;
  }
}

.button-light-icon .icon {
  margin-right: 16px;
  font-size: 16px;
}

button.button[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}

/** historico **/
.historic .historic-header {
  display: flex;
  justify-content: space-between;
}

.historic .historic-header .title {
  font-size: 10px;
  color: #7b808c;
  margin-bottom: 16px;
}

/** lista decimal **/
.wrapper-list-decimal {
  color: #423b3a;
  margin-bottom: 32px;
}

.wrapper-list-decimal .list-decimal {
  counter-reset: li;
  padding: 0;
}

.wrapper-list-decimal .list-decimal li {
  counter-increment: section;
  display: table;
  margin: 12px 0;
  color: #3a3d42;
  line-height: 1.5;
}

.wrapper-list-decimal .list-decimal li:before {
  content: counter(section) ". ";
  color: #01602a;
  padding-right: 15px;
  font-size: 20px;
  font-family: "Metropolis-bold";
  display: table-cell;
  vertical-align: center;
}

/** link download **/
.link-download {
  display: block;
  margin: 5px 0;
  font-size: 12px;
  color: #01602a;
  text-decoration: none;
}

.link-download-rea {
  display: block;
  color: #01602a;
  text-decoration: none;
}

.link-download .icon {
  margin-right: 8px;
}

/** número de contato **/
section.wrapper-number-contact > .title {
  font-size: 16px;
  font-family: "Metropolis-semiBold";
  color: #3a3d42;
}

.wrapper-number-contact .number-contact {
  display: flex;
  color: #3a3d42;
  margin: 16px 0;
}

.wrapper-number-contact .number-contact a {
  text-decoration: none;
  color: #3a3d42;
}

.wrapper-number-contact .number-contact .left .icon {
  margin-right: 24px;
  font-size: 20px;
  color: #01602a;
}

.wrapper-number-contact .number-contact .right .tel {
  text-decoration: none;
  font-size: 14px;
  line-height: 2;
  font-family: "Metropolis-semiBold";
  color: #3a3d42;
}

.no-hyperlink {
  text-decoration: none;
  color: #3a3d42;
}

/** modal **/
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: block;
  overflow: hidden;
  outline: 0;
  background: #0006;
  display: none;
}

.modal-backdrop.modal-open {
  display: block;
}

.modal {
  position: relative;
  width: auto;
  background: #fff;
}

.modal .modal-header {
  background: #01602A;
}

.modal .modal-header .icon {
  color: #DEE9D3;
  font-size: 24px;
  cursor: pointer;
}

.modal.modal-no-footer .modal-body {
  height: calc(100vh - 50px);
}

.modal .modal-header,
.modal .modal-footer {
  padding: 12px 16px;
}

.modal .modal-footer .button {
  margin: 0;
}

/* upload de foto roubo - codigo roubo */
.modal .codigo-roubo {
  padding: 16px;
}

.codigo-roubo {
  margin: 0 0 32px;
}

.codigo-roubo .codigo {
  margin-bottom: 8px;
  color: #3a3d42;
  font-size: 24px;
  font-family: "Metropolis-ExtraBold";
}

.codigo-roubo .data {
  color: #7b808c;
  font-size: 10px;
}
/* .modal-upload .modal-body{height:calc(100vh - 110px)} */
.modal-upload .modal-header .icon:last-child {
  display: block;
}

/** modal mensagem **/
.modal-mensagem {
  padding: 15px;
  width: 288px;
  position: absolute;
  background: #fff;
  right: 0;
  left: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

.modal-mensagem > .icon {
  font-size: 20px;
  cursor: pointer;
}

.modal-mensagem .modal-mensagem-body {
  text-align: center;
}

.modal-mensagem .modal-mensagem-body .icon {
  font-size: 80px;
  display: block;
  margin: 16px 0;
  color: #01602a;
}

.modal-mensagem .modal-mensagem-body .wrapper-mensagem {
  margin: 48px 0 32px;
}

.modal-mensagem .modal-mensagem-body .wrapper-mensagem .mensagem-principal {
  font-size: 24px;
  font-family: "Metropolis-ExtraBold";
  color: #3a3d42;
  padding-bottom: 16px;
}

.modal-mensagem .modal-mensagem-body .wrapper-mensagem .mensagem-secondaria {
  color: #7b808c;
}

.modal-mensagem:before,
.modal:before {
  content: "";
  height: 4px;
  width: 100%;
  background: #01602a;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
/* tipos de modal mensagem */
.modal-mensagem.warning:before,
.modal-mensagem.warning .modal-bottom .button {
  background: #f85843;
}

.modal-mensagem.warning .modal-mensagem-body .icon {
  color: #f85843;
}

/* Input File*/
.wrapper-file {
  margin: 16px 16px;
}

.wrapper-file .input-file-rea,
.input-file-multiple {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.wrapper-file .wrapper-input-file {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  width: 100%;
  font-family: "Metropolis-semiBold";
  color: #01602a;
  border: solid 1px #01602a;
  border-radius: 3px;
  line-height: 2;
}

.wrapper-file .wrapper-input-file .icon {
  font-size: 16px;
  margin-right: 8px;
}

.wrapper-input-file .file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper-file .label-input-file {
  display: flex;
  align-items: center;
}

.wrapper-file .remove-file {
  display: none;
  font-size: 16px;
  color: #ff0000b3;
  cursor: pointer;
}

.wrapper-file.has-file .remove-file {
  display: block;
}

.wrapper-file.has-file {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 0 16px;
}

.wrapper-file.has-file .label-input-file {
  padding: 11px 0;
}

.wrapper-file.has-file .wrapper-input-file {
  border: none;
  justify-content: flex-start;
  color: #7b808c;
  font-family: "Metropolis";
}

/** chips **/
.wrapper-chips {
  display: table;
  margin-right: -4px;
  margin-left: -4px;
}

.wrapper-chips .chips {
  margin: 4px;
}

.chips {
  background: #DEE9D3;
  padding: 6px 8px;
  border-radius: 3px;
  display: inline-block;
  margin: 4px 0;
  line-height: 1.6;
}

.chips .text {
  font-size: 12px;
  color: #01602a;
  font-family: "Metropolis-semiBold";
}

.chips .icon {
  margin-left: 10px;
  color: #01602a;
  font-size: 16px;
  top: 3px;
  position: relative;
  cursor: pointer;
}

/** dropdown **/
.dropdown .dropdown-header {
  color: #3a3d42;
  font-family: "Metropolis-semiBold";
  display: flex;
  align-items: center;
  font-size: 14px;
}

.dropdown .dropdown-header .icon {
  font-size: 20px;
  margin-right: 16px;
  color: #bfbfbf;
}

.dropdown .dropdown-content {
  display: none;
  margin: 12px 0;
}

.dropdown .dropdown-content .item-content {
  padding: 16px 0;
  color: #7b808c;
}

/** tela lista agendamento **/
.agendamentos {
  position: relative;
}

.agendamentos .wrapper-button-agendamento .button {
  display: none;
}

.agendamentos .wrapper-button-agendamento .float-button {
  position: fixed;
  top: 112px;
  right: 16px;
  color: #00000085;
  z-index: 2;
  display: flex;
}

/* chebox e radiobutton */
.label--checkbox,
.label--radio {
  position: relative;
  font-size: 14px;
  cursor: pointer;
  font-family: "Metropolis-semiBold";
  color: #3a3d42;
  display: flex;
  align-items: flex-start;
}

.label--radio {
  font-family: "Metropolis";
  margin: 10px 0;
}

.checkbox {
  position: relative;
  height: 0;
  width: 1px;
  border-color: transparent;
  background: transparent;
  margin: 0 35px 0 0;
  cursor: pointer;
}

.checkbox:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: rotate(-45deg) scale(0, 0);
  -moz-transform: rotate(-45deg) scale(0, 0);
  -ms-transform: rotate(-45deg) scale(0, 0);
  -o-transform: rotate(-45deg) scale(0, 0);
  transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 0.1875rem;
  top: 1px;
  z-index: 1;
  width: 0.75rem;
  height: 0.375rem;
  border: 2px solid #fff;
  border-top-style: none;
  border-right-style: none;
}

.checkbox:checked:before {
  -webkit-transform: rotate(-45deg) scale(1, 1);
  -moz-transform: rotate(-45deg) scale(1, 1);
  -ms-transform: rotate(-45deg) scale(1, 1);
  -o-transform: rotate(-45deg) scale(1, 1);
  transform: rotate(-45deg) scale(1, 1);
}

.checkbox:after {
  content: "";
  position: absolute;
  top: -3px;
  left: 0;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 3px;
  border: 2px solid #c4c4c4;
  cursor: pointer;
}

.checkbox:checked::after {
  background: #d5db26;
  border-color: #d5db26;
}

.radio {
  position: relative;
  margin: 0 24px 0 0;
  cursor: pointer;
  top: 2px;
}

.radio:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 0;
  left: 0.125rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.75rem;
  background: #d5db26;
  border-radius: 50%;
}

.radio:checked:before {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio:after {
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.125rem;
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 2px solid #c2c2c2;
  border-radius: 50%;
}

.radio:checked::after {
  border-color: #d6db26;
}

/* Panel */
.panel-header {
  text-transform: uppercase;
  color: #7b808c;
  font-family: "Inter";
  font-weight: bold;
  line-height: 3;

  font-size: 10px;
  cursor: pointer;
}

.panel-body {
  visibility: visible;
  opacity: 1;
}

.panel-body[aria-hidden="true"] {
  display: none;
}

/* tela home */
.home-desktop {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.home-desktop > div {
  flex: 1;
}

.home-desktop .item-left {
  margin-right: 18px;
  position: relative;
  min-height: 200px;
  max-width: 460px;
  width: 28%;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 24px;
  padding: 13px 3px 20px 3px;
}

@media (min-width: 2550px) {
  .home-desktop .item-left {
    max-width: 540px;
    width: 32%;
  }
}

@media (min-width: 3000px) {
  .home-desktop .item-left {
    max-width: 600px;
    width: 34%;
  }
}

.home-desktop .item-right {
  margin-right: 131px;
  position: relative;
  min-height: 200px;
  max-width: 460px;
  width: 28%;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 24px;
  padding: 11px 3px 20px 3px;
}

@media (min-width: 1550px) {
  .home-desktop .item-right {
    margin-right: 20px;
  }
}

@media (min-width: 1850px) {
  .home-desktop .item-right {
    margin-right: 110px;
  }
}

@media (min-width: 2200px) {
  .home-desktop .item-right {
    margin-right: 180px;
  }
}

@media (min-width: 2550px) {
  .home-desktop .item-right {
    margin-right: 200px;
    max-width: 540px;
    width: 32%;
  }
}

@media (min-width: 3000px) {
  .home-desktop .item-right {
    margin-right: 340px;
    max-width: 550px;
    width: 32%;
  }
}

.home-desktop .item-left > .wrapper-card .card,
.home-desktop .item-right > .wrapper-card .card {
  margin-bottom: 0;
  border-radius: 0;
}

.home-desktop .item-right > span,
.home-desktop .item-left > span {
  background: #ffffff;
  display: block;
  padding: 16px;
}

.home-desktop .item-left > .wrapper-card .card:before,
.home-desktop .item-right > .wrapper-card .card:before {
  display: none;
}

.home-desktop .float-button {
  display: none;
}

.home-desktop .wrapper-card .card .description .primary-info {
  font-family: "Inter";
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #3a3d42;
}

/* tela servicos contratados */
.servicos-contratados .wrapper-list-two-texts .list-two-text {
  font-size: 14px;
}
/* lista icone */
.list-icon {
  padding: 0;
  line-height: 1.5;
}

.list-icon li {
  display: flex;
  font-size: 14px;
  color: #3a3d42;
  margin: 16px 0;
  align-items: center;
}

.list-icon li .icon {
  margin-right: 24px;
  font-size: 20px;
  color: #01602a;
}

.list-icon li span {
  font-size: 14px;
}

/*  lista com dois textos */
.wrapper-list-two-texts {
  display: flex;
  align-items: top;
  margin: 16px 0;
  padding: 0;
  height: auto;
}

.wrapper-list-two-texts .icon {
  font-size: 20px;
}

.wrapper-list-two-texts .list-two-text {
  margin: 0;
  padding-left: 24px;
}

.wrapper-list-two-texts .list-two-text li span {
  display: block;
}

.wrapper-list-two-texts .list-two-text li .text-one {
  font-size: 14px;
  color: #3a3d42;
}

.wrapper-list-two-texts .list-two-text li .text-two {
  color: #7b808c;
  font-size: 10px;
  margin: 4px 0;
}

.wrapper-list-two-texts .list-two-text li {
  margin-bottom: 16px;
}

/* slider */
.slider {
  z-index: 6;
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  height: 100%;
  width: 416px;
  display: none;
}

.slider .slider-header {
  height: 88px;
  background: #01602a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  color: #fff;
}

.slider .slider-header .title {
  font-size: 14px;
  font-family: "Metropolis-ExtraBold";
}

.slider .slider-header .icon {
  color: #DEE9D3;
  font-size: 24px;
  cursor: pointer;
}

.slider .slider-body {
  padding: 16px 62px;
  overflow: auto;
  height: 100%;
  padding-bottom: 80px;
}

.slider .slider-body h2 {
  font-size: 24px;
  color: #3a3d42;
  font-family: "Metropolis-ExtraBold";
  padding-bottom: 24px;
}

.overlay.open,
.slider.open {
  display: block;
}

.overlay.close,
.slider.close {
  display: none;
}

.react-datepicker__time-list {
  padding: 0 !important;
}

/* autocomplete */
.react-autosuggest__suggestions-list {
  position: absolute;
  top: 20.5px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  padding: 8px 0;
  border: 1px solid #eee;
  border-top: 0;
  box-shadow: 0 11px 14px -10px #eee;
  background: #fff;
  z-index: 2;
}

.react-autosuggest__suggestions-list li {
  padding: 8px;
  font-family: "Metropolis-SemiBold";
  cursor: pointer;
}

.react-autosuggest__suggestions-list li:hover {
  background: #f0f1f5;
}

.react-autosuggest__container input,
.react-autosuggest__container label {
  padding-left: 8px;
  padding-right: 8px;
}

.wrapper-input-cidades .wrapper-input {
  padding-left: 0;
  padding-right: 0;
}

/** time/date picker **/
.react-datepicker__current-month {
  font-family: "Metropolis-ExtraBold";
  text-transform: uppercase;
  line-height: 2;
}

.react-datepicker__header {
  background: #f0f1f5;
  border: 1px solid #eee;
  border-radius: 0;
}

.react-datepicker__month-container {
  border-radius: 0;
}

.react-datepicker {
  border-radius: 0;
  border: 1px solid #eee;
}

.react-datepicker__navigation--next,
button.react-datepicker__navigation.react-datepicker__navigation--previous {
  top: 15px;
}

.react-datepicker__day-names {
  font-family: "Metropolis";
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #eee;
}

.react-datepicker__day.react-datepicker__day--fri.react-datepicker__day--disabled.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  border-radius: 0;
  background: #01602a;
  color: #fff;
}

.react-datepicker__week {
  border-radius: 0;
  font-family: "Metropolis";
}

.react-datepicker__day:hover {
  border-radius: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 0;
  background: #01602a;
}

.react-datepicker__header.react-datepicker__header--time {
  font-family: "Metropolis-ExtraBold";
  text-transform: uppercase;
  border-radius: 0;
}

.react-datepicker__day--keyboard-selected {
  background: #01602a;
  border-radius: 0;
  color: #fff;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #eee;
}
/* remover os timers desativados */
.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  display: none;
}
/* possibilitar clicar no icone de data para abrir o datepicker */
.wrapper-input .react-datepicker__input-container.group-input .input {
  width: 110%;
  z-index: 2;
}

.wrapper-input .react-datepicker__input-container.group-input .input {
  width: 100%;
  z-index: 2;
  color: #3a3d42;
  font-family: "Metropolis-semiBold";
}
/* desativado */
.react-datepicker-wrapper.wrapper-input #disabled {
  cursor: not-allowed;
}

/* loading */
.wrapper-component-loading {
  position: relative;
  display: block;
}

.wrapper-component-loading .backdrop {
  position: absolute;
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: #000;
  opacity: 0.3;
  top: 0;
  left: 0;
}

.backdrop .spinner {
  width: 40px;
  height: auto;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* links desativados */
.cursor-none {
  cursor: auto;
}

/* Util */
.text-upper {
  text-transform: uppercase;
}

.relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-15 {
  margin-bottom: 15px;
}

/**/
.lz-span-msg {
  display: block;
  margin-top: 15px;
}

/* tela de autorizar cookie */
.autorizar-cookie-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
  background: #fff;
}

.autorizar-cookie-wrapper .autorizar-cookie {
  width: 500px;
  margin: 0 auto;
  top: 40px;
  max-width: 100%;
  box-shadow: 1px 1px 1px 1px #cecece;
  padding: 15px;
  display: table;
  position: relative;
  background: #fff;
}

.autorizar-cookie-wrapper .autorizar-cookie .button {
  width: 120px;
  float: right;
}

.autorizar-cookie-wrapper input.input {
  margin-right: 10px;
  color: #000;
  position: relative;
  top: 3px;
}

.autorizar-cookie-wrapper .label {
  color: #5f6367;
}

/*GLOBAL*/
.w-100 {
  width: 100%;
}

.w-66 {
  width: 66%;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.align-center {
  align-items: center;
}

p.subtitle {
  text-transform: uppercase;
  color: #7b808c;
  margin-top: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  -ms-flex-wrap: wrap;
}

.row > * {
  padding: 0 10px;
  width: 100%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-8 {
  flex: 0 0 66.66%;
  max-width: 66.66%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.ml-auto {
  margin-left: auto;
}

.mt-3 {
  margin-top: 1rem;
}

.mr-3 {
  margin-right: 20px;
}

/*FORM*/
textarea.input {
  resize: vertical;
  height: 100px;
}

.radio-title {
  margin: 5px 0;
}

.form-intro {
  font-size: 1.1rem;
}

.form-title {
  font-size: 1.2rem;
}

.button {
  border: 1px solid transparent;
}

.btn-secondary {
  background: #DEE9D3;
  border: 1px solid #ddd;
  color: #01602a;
}

/*RADIO BUTTON*/
.custom-radio {
  padding-left: 1.5rem;
  position: relative;
  line-height: 20px;
  margin-bottom: 10px;
}

.custom-radio p.subtitle {
  margin: 0;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-radio .custom-control-label {
  font-size: 1rem;
}

.custom-radio .custom-control-label::before {
  content: "";
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  height: 1rem;
  width: 1rem;
  left: 0;
  top: 0;
  display: block;
  border: 1px solid #bababa;
}

.custom-radio .custom-control-label::after {
  position: absolute;
  top: 1px;
  left: 1px;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 75%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2301602A'/%3E%3C/svg%3E");
}

/*ACIMA DE MD*/
@media (min-width: 768px) {
  /*GLOBAL*/
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }

  .d-md-inline-flex {
    display: inline-flex;
    margin-right: 10px;
  }

  .voltarLastEtape {
    float: right;
  }
}

/*ACIMA DE LG*/
@media (min-width: 1025px) {
  /*GLOBAL*/
  /*	.w-lg-100 {width: 100%;}
	.w-lg-66 {width: 66%;}*/

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-none {
    display: none;
  }

  .voltarLastEtape {
    width: 15em;
  }
}

/*ACIMA DE XL*/
@media (min-width: 1300px) {
  /*GLOBAL*/
  .w-xl-100 {
    width: 100%;
  }

  .w-xl-66 {
    width: 66%;
  }
}

.font18 {
  font-size: 18px;
}

.marginBotton0 {
  margin-bottom: 0px;
}

.mensagemModalEmail {
  width: 100% !important;
  top: 2px !important;
}

.wrapper-input-modal-email {
  background: #f0f1f5;
  display: block;
  width: 81%;
  padding: 8px 10px;
  margin-bottom: 16px;
  position: relative;
  float: left;
}

.text-left {
  text-align: left !important;
}

.wapper-email-modal {
  width: 80% !important;
  float: left !important;
  padding-bottom: 13px;
}

/* modal indicar condutor */
.modal-indicar-condutor .modal {
  width: 90%;
  max-width: 90%;
  background: #e3e3e3;
  height: 80vh;
  overflow: hidden;
}
.imgBanner {
  max-width: 100%;
}
.modal-indicar-condutor .modal .modal-body {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.modal-indicar-condutor .modal .modal-body iframe {
  width: 100%;
  height: 100%;
  border: 0;
  padding-bottom: 65px;
}

.modal-indicar-condutor .modal .modal-hearder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-family: "Metropolis-Extrabold";
  font-size: 14px;
  background: #e5e5e5;
}

.modal-indicar-condutor .modal .modal-hearder .icon {
  cursor: pointer;
  font-size: 20px;
}

/* modal interposicao de recursos */
.wapper-modal-interposicao-recursos .modal {
  max-width: 300px;
  height: auto;
}

.form-interposicao-recursos {
  padding: 20px;
}

.form-interposicao-recursos .button {
  margin-top: 20px;
}

.wapper-modal-interposicao-recursos .modal .icon {
  font-size: 20px;
  cursor: pointer;
}

.wapper-modal-interposicao-recursos .modal-hearder {
  padding-top: 20px;
  padding-left: 20px;
}

.form-interposicao-recursos h2 {
  text-align: center;
  margin: 0 0 30px;
  font-family: "Metropolis-Extrabold";
  font-size: 16px;
  text-transform: uppercase;
}

/* indicar condutor */
.iframe-indicar-condutor {
  width: 100%;
  height: calc(100vh - 70px);
  border: 0;
  box-shadow: none;
}

.botaoSomarEmail {
  width: 20%;
  height: 47px;
  background: no-repeat;
  border: 1px solid;
  border-color: #01602a;
  color: #01602a;
  cursor: pointer;
}

.gridEmailLista {
  font-size: 11px;
  font-weight: normal;
}

.divLeftModalEmail {
  width: 90%;
  float: left;
}

.divRightModalEmail {
  width: 10%;
  float: right;
}

.bton-excluir-grid-emails {
  background: none;
  border: 0;
  color: #ab1818;
  font-size: 12px;
  padding-top: 0px;
  cursor: pointer;
}

.ico-datepiker-component {
  bottom: -8px !important;
}

.red {
  color: #f13b3b !important;
}

.toUpperCase {
  text-transform: uppercase;
}
.text-justify {
  text-align: justify;
}
.text-decoration-none {
  text-decoration: none;
}
